
import { Options, Vue } from 'vue-class-component'
import { SessionModule } from '@/store/modules/session'
import { User } from '@/types'

@Options({
  name: 'ProfileView',
})
export default class ProfileView extends Vue {
  update(): void {
    SessionModule.updateUserInfo()
  }

  get loadingUser(): boolean {
    return SessionModule.updatingUser
  }

  get user(): User | null {
    return SessionModule.user
  }

  get profilePhoto(): string {
    const img = SessionModule.profilePhoto
    if (img === null) {
      return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
    }

    return `data:image/jpeg;base64,${img}`
  }
}
